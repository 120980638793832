@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}

/* Custom CSS to hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* below css class replace bootstrap background color with daisy */
.op-bg-primary {
  @apply bg-primary;
}
.op-bg-secondary {
  @apply bg-secondary;
}
.op-bg-info {
  @apply bg-info;
}
.op-bg-success{
  @apply bg-success;
}
.op-bg-warning {
  @apply bg-warning;
}

/* below css class replace bootstrap text color with daisy */
.op-text-primary {
  @apply text-primary;
}
.op-text-secondary {
  @apply text-secondary;
}
.op-text-info {
  @apply text-info;
}
.op-text-success{
  @apply text-success;
}
.op-text-warning {
  @apply text-warning;
}
.op-border-primary{
  @apply border-primary
}
/* CSS for scrollbar customization */
* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: gray; /* For Firefox */
}

/* For webkit-based browsers like Chrome, Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}